<template>
	<div>
		<div v-if="loading">
			<Loader size="20" />
		</div>
		<multiselect
			v-else-if="assets"
			track_by="id"
			label="name"
			:multiple="multiple ? multiple : false"
			:close_on_select="multiple ? false : true"
			v-model="selected"
			:options="assets"
			:allow_select_all="allow_select_all"
			:searchable="true"
			:placeholder="$tc('location.select_location', multiple ? 2 : 1)"
		></multiselect>
	</div>
</template>

<script>
import Loader from "@loader";

import Multiselect from "@form_components/Multiselect";
import AssetsFinderClass from "@src/asset/application/assets-finder";

const AssetsFinder = new AssetsFinderClass();

export default {
	name: "LocationSelect",
	props: {
		value: [Array, String],
		multiple: Boolean,
		allow_select_all: Boolean,
		assets_filter: Function
	},
	components: {
		Loader,
		Multiselect
	},
	data() {
		return {
			loading: false,
			assets: null,
			selected: this.value
		};
	},
	async mounted() {
		this.loading = true;
		try {
			let result = await AssetsFinder.get_user_assets();

			if (this.assets_filter) {
				result = result.filter((item) => this.assets_filter(item));
			}

			var multiselect_assets = [];
			for (var key in result) {
				const asset = result[key];

				multiselect_assets.push({
					id: asset.id,
					name: asset.name
				});
			}

			this.assets = multiselect_assets;
		} catch (error) {
		} finally {
			this.loading = false;
		}
	},
	watch: {
		selected: function (value) {
			this.$emit("input", value);
		},
		value: function (val) {
			this.selected = val;
		}
	}
};
</script>
