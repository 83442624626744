<template>
	<div
		v-if="status && id"
		class="ticket-status text-center rounded-m p-h-xs margin-center white full-width"
		v-bind:style="{
			backgroundColor: status.color
		}"
	>
		{{ status.name }}
	</div>
</template>

<script>
import TicketServiceClass from "@services/user-services/ticket-service";

const TicketService = new TicketServiceClass();

export default {
	name: "TicketStatus",
	props: {
		id: String
	},
	data() {
		return {
			status: null
		};
	},
	async created() {
		const all_status = await TicketService.get_ticketing_status();
		if (all_status[this.id]) this.status = all_status[this.id];
	}
};
</script>

<style>
.ticket-status {
	line-height: 14px;
}
</style>
